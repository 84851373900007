export const actions = {
  setSearchedString: 'SET::SEARCH_QUERY_STRING_PARAMETER',
  setSearchedType: 'SET::SEARCHED_TYPE',
  setSearchResults: 'SET::SEARCH_RESULTS',
  setScrollToId: 'SET::SCROLL_TO_ELEMENT_ID',
  setSelectedIncident: 'SET::SELECTED_INCIDENT_DETAILS',
  setSearchToggleValue: 'SET::SEARCH_TOGGLE_VALUE'
}

export const setSearchedString = query => ({ type: actions.setSearchedString, searchedString: query })
export const setSearchedType = type => ({ type: actions.setSearchedType, searchedType: type })
export const setSearchResults = results => ({ type: actions.setSearchResults, searchResults: results })
export const setScrollToId = elementId => ({ type: actions.setScrollToId, scrollToId: elementId })
export const setSelectedIncident = incidentDetails => ({ type: actions.setSelectedIncident, selectedIncident: incidentDetails })
export const setSearchToggleValue = toggleValue => ({ type: actions.setSearchToggleValue, searchToggleValue: toggleValue })

export default actions
