import actions from './actions'

const loading = (state = { isLoading: false }, action) => {
  switch (action.type) {
    case actions.setIsLoading:
      return { ...state, isLoading: action.isLoading }
    default:
      return state
  }
}

export default loading
