import React from 'react'
import { PropTypes } from 'prop-types'

import { Body1, Display1 } from '@jsluna/typography'
import { GridItem } from '@jsluna/grid'
import { StatusCard } from '@jsluna/card'

import { UNPAID, PAID, CIVIL_RECOVERY } from '../../utils/constants/searchToggleTypes'

const statusCardBorderColor = (toggleType) => {
  switch (toggleType) {
    case PAID:
      return 'u-border-green'
    case UNPAID:
      return 'u-border-grey'
    case CIVIL_RECOVERY:
      return 'u-border-orange'
    default:
      return ''
  }
}

const Results = props => {
  const handleIncidentCardOnClick = (incidentId, index) => {
    const { setSelectedIncident, setScrollToElementId, searchResults, history } = props
    setScrollToElementId(`result-card-${index}`)
    setSelectedIncident(searchResults.find(x => x.incidentId === incidentId))
    history.push('/incidentDetails')
  }

  return (
    props.data
      .sort((a, b) => a.surname.localeCompare(b.surname) || a.forename.localeCompare(b.forename))
      .map((res, index) => (
        <GridItem
          id={`result-card-${index}`}
          key={index}
          className="ln-u-margin-top*2"
          size={{ sm: '1/1' }}
          onClick={() => handleIncidentCardOnClick(res.incidentId, index)}
        >
          <StatusCard
            className={`ln-u-padding-ends ${statusCardBorderColor(res.incidentSearchToggleType)}`}
            link={true}
            status="none"
          >
            <div className="u-no-wrap-and-trim">
              <Display1>{`${res.forename} ${res.surname}`}<br /></Display1>
              <Body1>{res.customerAddress ? res.customerAddress : 'No address'}</Body1>
            </div>
          </StatusCard>
        </GridItem>
      ))
  )
}

Results.propTypes = {
  searchResults: PropTypes.array,
  setScrollToElementId: PropTypes.func.isRequired,
  setSelectedIncident: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
}

Results.defaultProps = {
  searchResults: [],
}

export default Results