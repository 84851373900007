import React, { useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'

import { Select, Label } from '@jsluna/form'
import { AsyncAutocompleteField } from '@jsluna/autocomplete'
import { LinkButton } from '@jsluna/button'
import { Body1 } from '@jsluna/typography'
import { GridWrapper, GridItem } from '@jsluna/grid'

import { useApiClient } from '../../utils/azure-ad/AppProvider'
import apiBaseUrl from '../../utils/apiConfig'
import Modal from '../../Components/Common/Modal/Modal'
import Warning from '../../Components/Messages/Warning/Warning'
import Success from '../../Components/Messages/Success'
import Receipt from '../../assets/receipt.jpeg'
import SearchBox from '../../Components/SearchBox/SearchBox'
import Toggle from '../../Components/Toggle'
import Results from '../../Components/Results'

import { TRANSACTION_ID, CUSTOMER_SURNAME, STORE_NAME } from '../../utils/constants/searchTypes'
import { UNPAID_PAID, GONE_TO_CIVIL_RECOVERY } from '../../utils/constants/searchToggles'
import { UNPAID, PAID, CIVIL_RECOVERY } from '../../utils/constants/searchToggleTypes'
import { STORE_LOCATOR_URL_MAIN_AND_LOCAL } from '../../utils/constants/urls'
import { isNullOrWhiteSpaces } from '../../utils/stringHelpers'

const Search = (props) => {
    const apiClient = useApiClient()
    const { 
      searchedString,
      searchedType,
      searchResults,
      searchToggleValue,
      setIsLoading,
      setSearchResults } = props

    const [searchTypes, setSearchTypes] = useState([])
    const [showNoRecordsFound, setShowNoRecordsFound] = useState(false)
    const [isSearchFieldEmpty, setIsSearchFieldEmpty] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [searchTypeFriendlyText, setSearchTypeFriendlyText] = useState('')
    const [civilRecoveryIncidentCount, setCivilRecoveryIncidentCount] = useState(0)
    const [unpaidPaidIncidentCount, setUnpaidPaidIncidentCount] = useState(0)

    const toggleButtons = [
        { label: UNPAID_PAID, value: UNPAID_PAID },
        { label: `${GONE_TO_CIVIL_RECOVERY} (${civilRecoveryIncidentCount <= 9 ? civilRecoveryIncidentCount : '9+'})`, value: GONE_TO_CIVIL_RECOVERY }
    ]


    useEffect(() => {
        const handleError = (errorText, error) => {
          setIsLoading(false)
          console.log(errorText)
          console.log(error)
        }
        
        async function getIncidentSearchTypes() {
          const searchTypesUrl = `${apiBaseUrl}/IncidentSearch/SearchTypes`
          setIsLoading(true)
          const searchTypes = await apiClient.get(searchTypesUrl)
            .then((response) => {
              setIsLoading(false)
              return response.json()
            })
            .catch((error) => handleError('Error getting incident search types', error))
          setSearchTypes(searchTypes)
        }
        
        getIncidentSearchTypes()
    }, [apiClient])

    useEffect(() => {
        setCivilRecoveryIncidentCount(searchResults.filter(x => x.incidentSearchToggleType === CIVIL_RECOVERY).length)
        setUnpaidPaidIncidentCount(searchResults.filter(x => x.incidentSearchToggleType === UNPAID || x.incidentSearchToggleType === PAID).length)
    }, [searchResults])

    useEffect(() => {
        const { scrollToElementId } = props
        const element = document.getElementById(scrollToElementId)
        if (element !== null) {
            element.scrollIntoView()
        }
    }, [])

    useEffect(() => {
        switch (searchedType) {
            case TRANSACTION_ID:
                setSearchTypeFriendlyText('transaction ID')
                break;
            case CUSTOMER_SURNAME:
                setSearchTypeFriendlyText('customer surname')
                break;
            case STORE_NAME:
                setSearchTypeFriendlyText('store')
                break;

            default:
                break;
        }
    }, [searchedType])

    // takes focus off the AsyncAutocompleteField after search is performed to hide the keyboard on TC51
    useEffect(() => {
        if (searchResults !== [] && searchedType == STORE_NAME) document.activeElement.blur()
    }, [searchResults])

    useEffect(() => {
        const { setSearchResults } = props
        if (searchedString === '') {
            setSearchResults([])
        }
    }, [searchedString])

    // search options dropdown
    const getSearchOptions = () => {
        const sortedSearchTypes = [...searchTypes].sort((x, y) => x.displayOrder > y.displayOrder ? 1 : -1)

        return sortedSearchTypes.map(x => ({
            label: x.displayName, value: x.displayName
        }))
    }

    const handleSelectSearchType = (e) => {
        const { setSearchedType, setSearchedString, setSearchResults, setIncidentPaid, setSearchToggleValue } = props

        setSearchedType(e.target.value)
        setShowNoRecordsFound(false)
        setSearchedString('')
        setIsSearchFieldEmpty(false)
        setSearchResults([])
        setIncidentPaid(false)
        setSearchToggleValue(UNPAID_PAID)
    }

    const filterSearchResults = () => {
        let result = null
        if (searchToggleValue === UNPAID_PAID) {
            result = searchResults.filter(x => x.incidentSearchToggleType === UNPAID || x.incidentSearchToggleType === PAID)
        } else {
            result = searchResults.filter(x => x.incidentSearchToggleType === CIVIL_RECOVERY)
        }

        return result
    }



    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchBySurnameOrTransIdClick()
        }
    }

    const handleError = (errorText, error) => {
          setIsLoading(false)
          console.log(errorText)
          console.log(error)
        }
        
    async function getSearchResults(searchType, searchString) {
      const searchResultsUrl = `${apiBaseUrl}/IncidentSearch/Search?searchType=${searchType}&searchString=${searchString}`
      setIsLoading(true)
      const searchResults = await apiClient.get(searchResultsUrl)
        .then((response) => {
          setIsLoading(false)
          response.length === 0 ? setShowNoRecordsFound(true) : setShowNoRecordsFound(false)
          setIsSearchFieldEmpty(false)
          return response.json()
        })
        .catch((error) => handleError('Error getting search results', error))
        setSearchResults(searchResults)        
    }

    // Customer surname search
    const handleSearchBySurnameOrTransIdClick = () => {
        const { setSearchedString, setIncidentPaid, setSearchToggleValue } = props

        setIncidentPaid(false)
        setSearchToggleValue(UNPAID_PAID)
        if (searchedString) {
            if (isNullOrWhiteSpaces(searchedString)) {
                setShowNoRecordsFound(true)
                return
            }
            setSearchedString(searchedString)
            getSearchResults(searchedType, searchedString)
        }
        else {
            setIsSearchFieldEmpty(true)
            setShowNoRecordsFound(false)
        }
    }

    // Store search
    const handleStoreSearchClick = (event) => {
        const { setSearchedString, setSearchResults, setSearchToggleValue } = props
        setSearchToggleValue(UNPAID_PAID)
        if (event) {
            setSearchedString(event.label)
            getSearchResults(searchedType, event.value)
        }
        else {
            setIsSearchFieldEmpty(true)
            setShowNoRecordsFound(false)
            setSearchedString('')
            setSearchResults([])
        }
    }

    // TransactionId search
    const handleTransactionIdOnChange = (e) => {
        const { setSearchedString, setSearchResults } = props

        setSearchedString(e.target.value)
        setIsSearchFieldEmpty(false)

        if (e.target.value.length === 0) {
            setShowNoRecordsFound(false)
            setSearchResults([])
        } else {
            setIsSearchFieldEmpty(false)
        }
    }

    const handleSurnameOnChange = (e) => {
        const { setSearchedString, setSearchResults } = props

        setSearchedString(e.target.value)
        setIsSearchFieldEmpty(false)

        if (e.target.value.length === 0) {
            setShowNoRecordsFound(false)
            setSearchResults([])
        } else {
            setIsSearchFieldEmpty(false)
        }
    }

    // Error messages
    const renderNoRecordsFoundMessage = () => {
        if (showNoRecordsFound) {
            return (
                <div className="ln-u-margin-top*2">
                    <Warning className="ln-u-margin-top" message={`There are no records that match that ${searchTypeFriendlyText}`} />
                </div>
            )
        }
    }

    const renderRecordsInformationMessage = () => {
        if (searchToggleValue === GONE_TO_CIVIL_RECOVERY && civilRecoveryIncidentCount === 0 & searchedString !== '' && !showNoRecordsFound) {
            return (
                <Body1>No <q>Gone to Civil Recovery</q> records found.</Body1>
            )
        }

        if (searchToggleValue === UNPAID_PAID && unpaidPaidIncidentCount === 0 && searchedString !== '' && !showNoRecordsFound) {
            return (
                <Body1>No <q>Unpaid/Paid</q> records found.</Body1>
            )
        }

        return (
            <Body1>Records that are over 28 days old are not shown.</Body1>
        )
    }

    // Success message
    const renderSuccessMessage = () => {
        const { incidentPaid } = props
        if (incidentPaid === true) {
            return (
                <div className="ln-u-padding-bottom*2">
                    <Success message='Record has been updated' />
                </div>
            )
        }
    }

    const handleStoreSearchChange = (e) => {
        const { setSearchedString } = props
        setSearchedString(e.target.value)
    }

    const loadOptions = () =>
        fetch(STORE_LOCATOR_URL_MAIN_AND_LOCAL)
            .then(response => response.json())
            .then(response =>
                response.results.map(item => ({
                    label: item.other_name,
                    value: item.code
                }))
            )

    const clearSearchField = () => {
        const { setSearchedString, setSearchResults, setIncidentPaid } = props
        setSearchedString('')
        setSearchResults([])
        setIncidentPaid(false)
    }

    // Toggle 
    const handleToggleButtonClick = (value) => {
        const { setSearchToggleValue } = props
        setSearchToggleValue(value)
    }

    // Rendering logic
    const renderSearchComponent = () => {
        const { setSearchedType } = props

        switch (searchedType) {
            case TRANSACTION_ID:
                setSearchedType(TRANSACTION_ID)
                return (
                    <>
                        {
                            <div className="u-margin-top-sm">
                                <SearchBox
                                    identifier={searchedType}
                                    value={searchedString}
                                    inputMode='numeric'
                                    type='text'
                                    maxLength={4}
                                    placeholderText='e.g. 1234'
                                    onChange={(e) => handleTransactionIdOnChange(e)}
                                    onKeyPress={(e) => handleEnterKeyPress(e)}
                                    onClick={() => handleSearchBySurnameOrTransIdClick()}
                                    onClear={() => clearSearchField()}
                                    errorMessage={isSearchFieldEmpty ? 'Enter 1 to 4 digits' : ''}
                                />
                            </div>
                        }
                    </>
                )
            case CUSTOMER_SURNAME:
                setSearchedType(CUSTOMER_SURNAME)

                return (
                    <>
                        {
                            <div className="u-margin-top-sm">
                                <SearchBox
                                    identifier={searchedType}
                                    value={searchedString}
                                    type='text'
                                    placeholderText='e.g. Smith'
                                    onChange={(e) => handleSurnameOnChange(e)}
                                    onClick={() => handleSearchBySurnameOrTransIdClick()}
                                    onKeyPress={(e) => handleEnterKeyPress(e)}
                                    onClear={() => clearSearchField()}
                                    errorMessage={isSearchFieldEmpty ? 'Enter a surname' : ''}
                                />
                            </div>
                        }
                    </>
                )
            case STORE_NAME:
                setSearchedType(STORE_NAME)

                return (
                    <>
                        <AsyncAutocompleteField
                            identifier={searchedType}
                            className="u-margin-top-sm ln-u-flush-bottom c-text-no-underline"
                            name="store-search-auto-complete-field"
                            placeholder="e.g. Coventry"
                            minChars={3}
                            value={searchedString}
                            loadOptions={loadOptions}
                            onSelect={(e) => handleStoreSearchClick(e)}
                            // onChange also handles clearing the field when e === null. using OnClear with this AsyncAutocomplete component doesn't behave as expected
                            onChange={(e) => handleStoreSearchChange(e)}
                        />
                    </>
                )
            default: return null
        }
    }

    // Render Search page
    return (
        <>
            <Modal
                isOpen={isModalOpen}
                closeModal={() => setIsModalOpen(false)}
                title="Transaction ID location on receipt"
                text="You don't add the # symbol."
                image={<img src={Receipt} alt="Where to find transaction ID on receipt" />}
            />
            <GridWrapper className="ln-u-padding-left*2">
                <GridItem className="ln-u-hard-left" size={{ xs: '1/1' }}>
                    {renderSuccessMessage()}
                </GridItem>
                <Label>Search for a record</Label>
                <GridWrapper>
                    <GridItem size={{ xs: '1/1', md: '1/2' }}>
                        {
                            searchTypes.length > 0 &&
                            <Select
                                className="ln-u-margin-bottom"
                                name="incident-search-selector"
                                options={getSearchOptions()}
                                defaultValue={searchedType}
                                placeholder={false}
                                onChange={(e) => handleSelectSearchType(e)}
                            />
                        }
                        {
                            searchedType === TRANSACTION_ID &&
                            <LinkButton
                                id="where-to-find-transID-modal-link"
                                className="c-text-arial-bold"
                                onClick={() => setIsModalOpen(true)}
                                bare
                            >
                                Where to find the transaction ID
                            </LinkButton>
                        }
                    </GridItem>
                    <GridItem size={{ xs: '1/1', md: '1/2' }}>
                        {renderSearchComponent()}
                    </GridItem>
                </GridWrapper>
                <GridWrapper>
                    {
                        searchResults?.length > 0 &&
                        <>
                            <GridItem
                                className="u-margins-top-sm-times-two"
                                size={{ xs: '1/1' }}>
                                <Body1>{`${searchResults.length} ${searchResults.length > 1 ? 'records' : 'record'} found for this ${searchTypeFriendlyText}`}</Body1>
                            </GridItem>
                            <GridItem size={{ xs: '1/1' }}>
                                <Toggle
                                    buttons={toggleButtons}
                                    defaultToggleValue={searchToggleValue}
                                    onChange={(value) => handleToggleButtonClick(value)}
                                />
                            </GridItem>
                        </>
                    }
                    {
                        searchResults?.length > 0
                        && <Results
                            data={filterSearchResults()}
                            history={props.history}
                        />
                    }
                    <GridItem size={{ xs: '1/1' }}>
                        {renderNoRecordsFoundMessage()}
                    </GridItem>
                    <GridItem className="ln-u-text-align-center ln-u-margin-ends*2" size={{ xs: '1/1' }}>
                        {renderRecordsInformationMessage()}
                    </GridItem>

                </GridWrapper>
            </GridWrapper>
        </>
    )
}

Search.propTypes = {
    searchedType: PropTypes.oneOfType([
        TRANSACTION_ID,
        CUSTOMER_SURNAME,
        STORE_NAME
    ]),
    searchedString: PropTypes.string,
    searchResults: PropTypes.array,
    scrollToElementId: PropTypes.string,
    searchToggleValue: PropTypes.string.isRequired,
    civilRecoveryIncidentCount: PropTypes.Number,
    unpaidPaidIncidentCount: PropTypes.Number,
    setSearchedString: PropTypes.func.isRequired,
    setSearchedType: PropTypes.func.isRequired,
    setScrollToElementId: PropTypes.func.isRequired,
    setSearchResults: PropTypes.func.isRequired,
    setSelectedIncident: PropTypes.func.isRequired,
    setShowLoadingSpinner: PropTypes.bool.isRequired,
    setIncidentPaid: PropTypes.func.isRequired,
    setSearchToggleValue: PropTypes.func.isRequired,
    setCivilRecoveryIncidentCount: PropTypes.func,
    setUnpaidPaidIncidentCount: PropTypes.func,
    incidentPaid: PropTypes.bool,
    setIsLoading: PropTypes.func.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired
}

Search.defaultProps = {
    searchedType: TRANSACTION_ID,
    searchedString: '',
    searchResults: [],
    scrollToElementId: ''
}

export default Search
