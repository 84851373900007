import actions from './actions'

const incident = (state = { incidentPaid: false }, action) => {
    switch (action.type) {
        case actions.setIncidentPaid:
            return { ...state, incidentPaid: action.incidentPaid }
        default:
            return state
    }
}

export default incident