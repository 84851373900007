import { connect } from 'react-redux'
import Information from './Information'
import { setSearchResults, setSelectedIncident, setSearchedString, setSearchedType, setSearchToggleValue } from '../../store/searchHistory/actions'
import { setIncidentPaid } from '../../store/incident/actions'

const mapDispatchToProps = dispatch => ({
    setSearchResults: (state) => {
        dispatch(setSearchResults(state))
    },
    setSelectedIncident: (state) => {
        dispatch(setSelectedIncident(state))
    },
    setIncidentPaid: (state) => {
        dispatch(setIncidentPaid(state))
    },
    setSearchedString: (state) => {
        dispatch(setSearchedString(state))
    },
    setSearchedType: (state) => {
        dispatch(setSearchedType(state))
    },
    setSearchToggleValue: (state) => {
        dispatch(setSearchToggleValue(state))
    }
})

export default connect(null, mapDispatchToProps)(Information)
