import React from 'react'
import { PropTypes } from 'prop-types'

import { Alert } from '@jsluna/labs'
import { ButtonText as Heading } from '@jsluna/typography'

const Warning = (props) => {
    const { title, message, subMessage, className } = props
    return (
        <Alert warning className={className}>
            <div className="ln-u-margin-bottom">
                <Heading>{title}</Heading>
            </div>
            <p className="c-text-arial-bold ln-u-flush">{message}</p>
            <p className="c-text-arial-bold ln-u-flush ln-u-margin-top">{subMessage}</p>
        </Alert>
    )
}

Warning.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    subMessage: PropTypes.string,
    className: PropTypes.string
}

Warning.defaultProps = {
    title: '',
    subMessage: '',
    className: ''
}

export default Warning