import React from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { SiteLayout } from '@jsluna/site-layout'
import { Header as LunaHeader, HeaderLogo, HeaderNav } from '@jsluna/header'
import Logout from '../Logout/Logout'
import { Display2, Body1 } from '@jsluna/typography'

const Header = ({ fullName }) => {
    return (    
        <SiteLayout className="ln-u-margin-bottom*2">
            <LunaHeader>
            <HeaderLogo>
                <Display2>PFS Return to Pay</Display2>
            </HeaderLogo>
            <HeaderNav className="c-header" drawer tabBar="max-md" label="Main">       
            <Body1>{fullName}</Body1>
            <Logout />
            </HeaderNav>
            </LunaHeader>
        </SiteLayout>
    )
}

const mapStateToProps = state => ({
  fullName: state.user.fullName
})

export default connect(mapStateToProps, null)(Header)

Header.propTypes = {
    fullName: PropTypes.string
}
