import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'

import { Card } from '@jsluna/card'
import { Display3, ButtonText as SubHeading, Body1, Display1 } from '@jsluna/typography'
import { FilledButton, IconButton } from '@jsluna/button'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { ArrowLeft } from '@jsluna/icons'

import { useApiClient } from '../../utils/azure-ad/AppProvider'
import apiBaseUrl from '../../utils/apiConfig'
import { TRANSACTION_ID } from '../../utils/constants/searchTypes'
import { PAID, CIVIL_RECOVERY, UNPAID } from '../../utils/constants/searchToggleTypes'
import Warning from '../../Components/Messages/Warning/Warning'

const IncidentDetails = (props) => {
    const apiClient = useApiClient()
    const { selectedIncident, history, setIncidentPaid, setIsLoading, incidentPaid } = props

    const [isButtonDisabled, setIsButtonDisabled] = useState(false)

    useEffect(() => {
        if (selectedIncident.incidentSearchToggleType !== UNPAID || incidentPaid) {
            setIsButtonDisabled(true)
        } else {
            setIsButtonDisabled(false)
        }
    }, [selectedIncident, incidentPaid])

    useEffect(() => {
        window.scrollTo(0, 0)
        document.getElementById('backArrow').classList.add('ln-u-hard', 'u-svg-box-size')
    }, [])

    const handleBackToSearchClick = () => {
        setIncidentPaid(false)
        history.goBack()
    }

    const handleUpdateIncidentResponse = (response) => {
        const { setSearchResults, setSearchedType, setSearchedString } = props
        setSearchResults([])
        if (response === 200) {
            setIncidentPaid(true)
            setSearchedString('')
            setSearchedType(TRANSACTION_ID)
            history.push('/search')
        }
    }

    const handleError = (errorText, error) => {
          setIsLoading(false)
          console.log(errorText)
          console.log(error)
        }
        
    async function updateIncidentStatus(incidentId) {
      const updateIncidentUrl = `${apiBaseUrl}/IncidentSearch?incidentId=${incidentId}`
      setIsLoading(true)
      await apiClient.put(updateIncidentUrl)
        .then((response) => {
          setIsLoading(false)
          handleUpdateIncidentResponse(response)
          return response
        })
        .catch((error) => {
          setIsButtonDisabled(false)
          handleError('Error updating incident', error)
        })
    }

    const handlePaidButtonClick = () => {
        setIsButtonDisabled(true)
        updateIncidentStatus(selectedIncident.incidentId)
    }

    const getCivilRecoveryMessage = () => {
        // If on or after 1st March 2023 give new supplier's number
        if(new Date(selectedIncident.transactionDateTime) >= new Date('2023-03-01')){
            return <Body1>Ask the customer to contact our Civil Recovery partner BOSS on <b>01926 864 757</b> or email <b>info@bossuk.org</b></Body1>
        }
        
        return 'Ask customer to call: \n0113 204 1520'
    }

    const displayInformationCard = () => {
        const civilRecoveryMsg =  getCivilRecoveryMessage()

        if (selectedIncident.incidentSearchToggleType === PAID) {
            return (
                <Warning
                    className="ln-u-padding-left ln-u-margin-bottom"
                    title="Already been paid"
                    message="No further action is required"
                />
            )
        }

        if (selectedIncident.incidentSearchToggleType === CIVIL_RECOVERY) {
            return (
                <Warning
                    className="ln-u-padding-left ln-u-margin-bottom"
                    title={<Display1>Do not take payment</Display1>}
                    message= {<Body1>Gone to Civil Recovery</Body1>}
                    subMessage={civilRecoveryMsg}
                />
            )
        }
    }

    return (
        <>
            <IconButton
                id="back-to-search-button"
                className="ln-u-padding-left*2 c-text-arial-bold c-text-color-monochrome-dark-grey c-back-button-position"
                variant="text"
                label="Back to search"
                onClick={() => handleBackToSearchClick()}
            >
                <ArrowLeft id="backArrow" />
            </IconButton>
            <GridWrapper className="ln-u-padding-left*2 c-text-color-monochrome-dark-grey u-word-wrap">
                {displayInformationCard()}
                <Card className="ln-u-margin-bottom*2 ln-u-padding-top ln-u-padding-bottom*2 ln-u-hard-sides">
                    <GridItem className="ln-u-padding-left ln-u-margin-bottom*3">
                        <Display3>NMoP record</Display3>
                    </GridItem>
                    <GridItem className="ln-u-padding-left ln-u-margin-bottom">
                        <SubHeading>Transaction ID</SubHeading>
                    </GridItem>
                    <GridItem className="ln-u-padding-left ln-u-margin-bottom*2">
                        <Body1>{selectedIncident.sequenceNumber ? selectedIncident.sequenceNumber : '-'}</Body1>
                    </GridItem>
                    <GridItem className="ln-u-padding-left ln-u-margin-bottom">
                        <SubHeading>Transaction time</SubHeading>
                    </GridItem>
                    <GridItem className="ln-u-padding-left ln-u-margin-bottom*2">
                        <Body1>{selectedIncident.transactionTime ? selectedIncident.transactionTime : '-'}</Body1>
                    </GridItem>
                    <GridItem className="ln-u-padding-left ln-u-margin-bottom">
                        <SubHeading>Customer&apos;s name</SubHeading>
                    </GridItem>
                    <GridItem className="ln-u-padding-left ln-u-margin-bottom*2">
                        <Body1>{selectedIncident.customerName ? selectedIncident.customerName : '-'}</Body1>
                    </GridItem>
                    <GridItem className="ln-u-padding-left ln-u-margin-bottom">
                        <SubHeading>Customer&apos;s address</SubHeading>
                    </GridItem>
                    <GridItem className="ln-u-padding-left ln-u-margin-bottom*2">
                        <Body1>{selectedIncident.customerAddress ? selectedIncident.customerAddress : '-'}</Body1>
                    </GridItem>
                    <GridItem className="ln-u-padding-left ln-u-margin-bottom">
                        <SubHeading>Fuel type</SubHeading>
                    </GridItem>
                    <GridItem className="ln-u-padding-left ln-u-margin-bottom*2">
                        <Body1>{selectedIncident.fuelType ? selectedIncident.fuelType : '-'}</Body1>
                    </GridItem>
                    <GridItem className="ln-u-padding-left ln-u-margin-bottom">
                        <SubHeading>Payment amount due</SubHeading>
                    </GridItem>
                    <GridItem className="ln-u-padding-left ln-u-margin-bottom*3">
                        <Body1>{selectedIncident.fuelValue ? `£${selectedIncident.fuelValue.toFixed(2)}` : '-'}</Body1>
                    </GridItem>
                    {
                        selectedIncident.incidentSearchToggleType === UNPAID &&
                        <GridItem className="c-text-centre-align c-text-color-matterhorn-dark-grey ln-u-margin-bottom">
                            <p className="c-text-arial-bold">Please make sure you&apos;ve taken payment before continuing.</p>
                        </GridItem>
                    }
                    <GridItem className="ln-u-padding-left ln-u-padding-right">
                        <FilledButton
                            id="payment-made-on-till-button"
                            disabled={isButtonDisabled}
                            fullWidth
                            onClick={() => handlePaidButtonClick()}
                        >
                            Payment made on till
                        </FilledButton>
                    </GridItem>
                </Card>
            </GridWrapper>
        </>
    )
}

IncidentDetails.propTypes = {
    selectedIncident: PropTypes.object.isRequired,
    setIncidentPaid: PropTypes.func.isRequired,
    setSearchResults: PropTypes.func.isRequired,
    setSearchedType: PropTypes.func.isRequired,
    setSearchedString: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func.isRequired,
    incidentPaid: PropTypes.bool.isRequired,
    history: PropTypes.shape({
        goBack: PropTypes.func,
        push: PropTypes.func
    }).isRequired
}

export default IncidentDetails
