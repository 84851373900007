import { createContext, useContext, React } from 'react'
import { useMsal } from '@azure/msal-react'
import ApiClient from './ApiClient'

const AppContext = createContext(null)

// eslint-disable-next-line react/prop-types
export function AppProvider({ apiScope, children }) {
  const { instance } = useMsal()
  const apiClient = new ApiClient(
    instance,
    apiScope
  )

  return (
    <AppContext.Provider value={{ apiClient }}>
      {' '}
      { children }
      {' '}
    </AppContext.Provider>
  )
}

export function useApiClient() {
  const context = useContext(AppContext)

  if (!context) {
    throw new Error('useApiClient must be used within an AppProvider')
  }

  return context.apiClient
}
