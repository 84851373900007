import { connect } from 'react-redux'
import Toggle from './Toggle'
import { setSearchToggleValue } from '../../store/searchHistory/actions'

const mapStateToProps = state => ({
    searchToggleValue: state.searchHistory.searchToggleValue
})

const mapDispatchToProps = dispatch => ({
    setSearchToggleValue: (state) => {
        dispatch(setSearchToggleValue(state))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(Toggle)
