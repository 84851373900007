import { connect } from 'react-redux'
import Search from './Search'
import {
  setSearchedString,
  setSearchResults,
  setScrollToId,
  setSearchedType,
  setSelectedIncident,
  setSearchToggleValue
} from '../../store/searchHistory/actions'
import { setIncidentPaid } from '../../store/incident/actions'
import { setIsLoading } from '../../store/loading/actions'

const mapStateToProps = state => ({
  searchedString: state.searchHistory.searchedString,
  searchedType: state.searchHistory.searchedType,
  searchResults: state.searchHistory.searchResults,
  scrollToElementId: state.searchHistory.scrollToId,
  selectedIncident: state.searchHistory.selectedIncident,
  incidentPaid: state.incident.incidentPaid,
  searchToggleValue: state.searchHistory.searchToggleValue
})

const mapDispatchToProps = dispatch => ({
  setSearchedString: (state) => {
    dispatch(setSearchedString(state))
  },
  setSearchedType: (state) => {
    dispatch(setSearchedType(state))
  },
  setSearchResults: (state) => {
    dispatch(setSearchResults(state))
  },
  setScrollToElementId: (state) => {
    dispatch(setScrollToId(state))
  },
  setSelectedIncident: (state) => {
    dispatch(setSelectedIncident(state))
  },
  setIncidentPaid: (state) => {
    dispatch(setIncidentPaid(state))
  },
  setSearchToggleValue: (state) => {
    dispatch(setSearchToggleValue(state))
  },
  setIsLoading: (state) => {
    dispatch(setIsLoading(state))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)
