import React from 'react'
import { PropTypes } from 'prop-types'

import CardWithButton from '../../Components/Common/CardWithButton/CardWithButton'
import ERROR_MESSAGES from '../../utils/constants/errorMessages'

const Error = (props) => {
    const { history, text } = props
    let { title } = props
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const errorId = urlParams.get('errorId')

    switch (errorId) {
        case 'unauthorised':
            title = ERROR_MESSAGES.unauthorised
            break;
        default:
            break;
    }

    return (
        <CardWithButton
            title={title}
            text={text}
            buttonText="Please try again"
            onButtonClick={() => history.goBack()}
            isError={true}
        />
    )
}

Error.propTypes = {
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired
    }).isRequired,
    title: PropTypes.string,
    text: PropTypes.string,
}

Error.defaultProps = {
    title: ERROR_MESSAGES.default,
    text: null
}

export default Error