import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { composeWithDevTools } from 'redux-devtools-extension'

import user from './user/reducer'
import searchHistory from './searchHistory/reducer'
import incident from './incident/reducer'
import loading from './loading/reducer'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [] // array of strings - the names from the rootReducer
}

const rootReducer = combineReducers({
  user,
  searchHistory,
  incident,
  loading
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, composeWithDevTools())

const persistor = persistStore(store)

export { store, persistor }