import React from 'react'
import { PropTypes } from 'prop-types'

import { ToggleButtonGroup, ToggleButton } from '@jsluna/toggle-button'

const Toggle = (props) => {
    const { buttons, onChange, defaultToggleValue, setSearchToggleValue } = props

    const handleOnChange = (value) => {
        setSearchToggleValue(value)
        onChange(value)
    }

    return (
        <>
            <ToggleButtonGroup
                className="ln-u-margin-top*2"
                alpha
                value={defaultToggleValue}
                onChange={(event, value) => handleOnChange(value)}
            >
                {buttons.map(button => (
                    <ToggleButton
                        id={`toggle-button-${button.label.toLowerCase().replace(/ /g, '-').replace('/', '-').substring(0, 22)}`}
                        className="u-flex-no-flex-basis u-padding-ends-sm-times-four"
                        key={button.value}
                        value={button.value}
                    >
                        {button.label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </>
    )
}

Toggle.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    })).isRequired,
    defaultToggleValue: PropTypes.string.isRequired,
    setSearchToggleValue: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired
}

export default Toggle
