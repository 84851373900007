import { useEffect, useState } from 'react'
import { useApiClient } from '../utils/azure-ad/AppProvider'
import apiBaseUrl from '../utils/apiConfig'
import { store } from '../store/index'
import { setIsLoading } from '../store/loading/actions'

function UserPermissions() {
  const apiClient = useApiClient()
  const [authorised, setAuthorised] = useState(null)

  useEffect(() => {
    const handleError = (errorText, error) => {
    store.dispatch(setIsLoading(false))
    console.log(errorText)
    console.log(error)
  }

  async function getUserPermissions() {
    const userPermissionsUrl = `${apiBaseUrl}/Permission/HasAuthorisedAccess`
    store.dispatch(setIsLoading(true))
    const isAuthorised = await apiClient.get(userPermissionsUrl)
      .then((response) => {
        store.dispatch(setIsLoading(false))
        return response.status == 200 ? true : false
      })
      .catch((error) => handleError('Error checking if user has authorised access', error))
    setAuthorised(isAuthorised)
  }

  getUserPermissions()

  if (authorised === false && window.location.pathname !== '/error') {
    window.location.href = '/error?errorId=unauthorised'
  }

  }, [apiClient, authorised])

  return null
}

export default UserPermissions
