import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'

import { SearchField, withClear } from '@jsluna/form'
import { SearchButton } from '@jsluna/button'

const ClearableSearchField = withClear(SearchField)

const SearchBox = (props) => {
    const { identifier, value, inputMode, type,
        placeholderText, onChange, onClick,
        onKeyPress, onClear, errorMessage, maxLength } = props

    // removes margin from the bottom of the search field
    // two parent nodes required because the targeted element is nested deep inside luna
    useEffect(() => {
        document.getElementById(`clearable-search-${identifier}`)?.parentNode.parentNode.classList.add('ln-u-flush-bottom')
    }, [value])

    // removes margin from bottom of the search field when error message is displayed
    useEffect(() => {
        if (errorMessage.length > 0) {
            document.getElementsByClassName('ln-c-form-group has-error')[0].classList.add('ln-u-flush-bottom')
        }
    }, [errorMessage])

    return (
        <ClearableSearchField
            id={`clearable-search-${identifier.toLowerCase().replace(/ /g, '-')}`}
            key={identifier}
            name="incident-search-field"
            placeholder={placeholderText}
            value={value}
            type={type}
            inputMode={inputMode}
            minLength={1}
            maxLength={maxLength}
            hasButton
            button={<SearchButton id={`search-button-${identifier.toLowerCase().replace(/ /g, '-')}`} onClick={onClick} />}
            onChange={onChange}
            onKeyPress={onKeyPress}
            error={errorMessage}
            onClear={onClear}
        />
    )
}

SearchBox.propTypes = {
    identifier: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    inputMode: PropTypes.string,
    type: PropTypes.string,
    placeholderText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    onKeyPress: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    maxLength: PropTypes.number,
}

SearchBox.defaultProps = {
    type: 'text',
    inputMode: '',
    placeholderText: '',
    errorMessage: '',
    maxLength: 100
}

export default SearchBox
