import { connect } from 'react-redux'
import Results from './Results'
import {
  setSelectedIncident,
  setScrollToId
} from '../../store/searchHistory/actions'

const mapStateToProps = state => ({
  searchResults: state.searchHistory.searchResults
})

const mapDispatchToProps = dispatch => ({
  setSelectedIncident: (state) => {
    dispatch(setSelectedIncident(state))
  },
  setScrollToElementId: (state) => {
    dispatch(setScrollToId(state))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Results)
