import actions from './actions'

const user = (state = { fullName: null }, action) => {
  switch (action.type) {
    case actions.setUserFullName:
      return { ...state, fullName: action.fullName }
    default:
      return state
  }
}

export default user
