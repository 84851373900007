import actions from './actions'
import { TRANSACTION_ID } from '../../utils/constants/searchTypes'
import { UNPAID_PAID } from '../../utils/constants/searchToggles'


const searchHistory = (state = { searchResults: [], searchedString: '', searchedType: TRANSACTION_ID, scrollToId: '', selectedIncident: {}, searchToggleValue: UNPAID_PAID }, action) => {
  switch (action.type) {
    case actions.setSearchedString:
      return { ...state, searchedString: action.searchedString }
    case actions.setSearchedType:
      return { ...state, searchedType: action.searchedType }
    case actions.setSearchResults:
      return { ...state, searchResults: action.searchResults }
    case actions.setScrollToId:
      return { ...state, scrollToId: action.scrollToId }
    case actions.setSelectedIncident:
      return { ...state, selectedIncident: action.selectedIncident }
    case actions.setSearchToggleValue:
      return { ...state, searchToggleValue: action.searchToggleValue }
    default:
      return state
  }
}

export default searchHistory
