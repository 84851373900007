import React from 'react'
import { PropTypes } from 'prop-types'

import { Alert, AlertIcon } from '@jsluna/labs'
import { Tick } from '@jsluna/icons'

const Success = (props) => {
    const { message } = props
    return (
        <Alert success className="c-text-arial-bold">
            <AlertIcon>
                <Tick />
            </AlertIcon>
            {message}
        </Alert>
    )
}

Success.propTypes = {
    message: PropTypes.string.isRequired
}

export default Success
