import React from 'react'
import { PropTypes } from 'prop-types'

import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'

const LoadingOverlay = (props) => {
    const { hasBackground } = props

    return (
        <ProgressIndicator
            className={hasBackground ? 'c-loadingOverlay-background ln-u-justify-content-center className="ln-u-push-right-sm"' : 'c-loadingOverlay ln-u-justify-content-center'}
            loading
            page
            preventFocus
        >
            <ProgressSpinner size="large" className="ln-u-push-right-sm" />
        </ProgressIndicator >
    )
}

LoadingOverlay.propTypes = {
    hasBackground: PropTypes.bool.isRequired
}

LoadingOverlay.defaultProps = {
    hasBackground: true
}

export default LoadingOverlay
