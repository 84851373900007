import React from 'react'
import { PropTypes } from 'prop-types'

import { Display1 } from '@jsluna/typography'

import 'react-responsive-modal/styles.css';
import { Modal as ReactModal } from 'react-responsive-modal';

const Modal = (props) => {
    const { isOpen, title, text, image, closeModal } = props

    return (
        <ReactModal
            styles={{ modal: { marginLeft: 0, marginRight: 0 } }}
            open={isOpen}
            onClose={closeModal}
            center
        >
            <div className="ln-u-margin-top*3">
                <Display1>{title}</Display1>
            </div>
            <p className="ln-u-margin-top">{text}</p>
            {image}
        </ReactModal>
    )
}

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    image: PropTypes.element
}

Modal.defaultProps = {
    isOpen: false,
    text: '',
    image: null
}

export default Modal
