import React from 'react'
import { PropTypes } from 'prop-types'

import { Button } from '@jsluna/button'

const Placeholder = (props) => {
    const { history } = props

    return (
        <>
            <h3>Placeholder page</h3>
            <p>This page needs to be replaced as we develop the web app</p>
            <Button onClick={() => history.goBack()}>Back</Button>
        </>
    )
}

Placeholder.propTypes = {
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired
    }).isRequired
}

export default Placeholder
