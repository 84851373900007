/* eslint-disable */
export const msalConfig = {
  auth: {
    // Store operations template frontend App registration on JSTest3 Tenant is used as a default. Must replace with your own App registration
    clientId: window?.env?.REACT_APP_MSAL_CLIENT_ID ?? 'e7bb3900-d332-44cd-bb40-28e6861aee54',
    authority: window?.env?.REACT_APP_MSAL_AUTHORITY ?? 'https://login.microsoftonline.com/b2eb8529-51f8-4d43-92bf-85201eea6023',
    redirectUri: window?.env?.REACT_APP_MSAL_REDIRECT_URL ?? 'http://localhost:3000',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
}
