import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'

import { TRANSACTION_ID } from '../../utils/constants/searchTypes'
import CardWithButton from '../../Components/Common/CardWithButton/CardWithButton'

const Information = (props) => {
    const {
        history,
        setSearchResults,
        setSelectedIncident,
        setIncidentPaid,
        setSearchedType,
        setSearchedString,
        setSearchToggleValue
    } = props

    useEffect(() => {
        setSearchResults([])
        setSelectedIncident({})
        setIncidentPaid(false)
        setSearchedType(TRANSACTION_ID)
        setSearchedString('')
        setSearchToggleValue('')
    }, [])

    return (
        <CardWithButton
            buttonID="continue-button-info-page"
            title="PFS Outstanding Payments"
            text="This application is only to be used when taking an outstanding payment for another PFS."
            buttonText="Continue"
            onButtonClick={() => history.push('/search')}
            isError={false}
        />
    )
}

Information.propTypes = {
    setSearchResults: PropTypes.func,
    setSelectedIncident: PropTypes.func,
    setIncidentPaid: PropTypes.func,
    setSearchedType: PropTypes.string,
    setSearchedString: PropTypes.string,
    setSearchToggleValue: PropTypes.string,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
}

export default Information