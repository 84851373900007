import React from 'react'
import { PropTypes } from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import { GridWrapper, GridItem } from '@jsluna/grid'
import { Modal } from '@jsluna/modal'
import { InteractionType } from '@azure/msal-browser'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication
} from '@azure/msal-react'

import User from '../User/User'
import UserPermissions from '../UserPermissions/UserPermissions'
import Header from '../Components/Header/Header'
import Information from '../Pages/Information'
import Placeholder from '../Pages/Placeholder/Placeholder'
import Search from '../Pages/Search'
import Error from '../Pages/Error/Error'
import LoadingOverlay from '../Components/LoadingOverlay/LoadingOverlay'
import IncidentDetails from '../Pages/IncidentDetails'
import { apiConfig } from '../utils/azure-ad/apiClientConfig'

function App(props) {
  const { isLoading } = props

  useMsalAuthentication(InteractionType.Redirect, {
    scopes: [apiConfig.apiClientScope],
  })

  return (
    <>
      <AuthenticatedTemplate>
        {isLoading && <LoadingOverlay />}
        <User />
        <UserPermissions />
        <Header />
        <GridWrapper className="ln-u-flush-left b-scaffold-body" horizontalAlign='center'>
          <GridItem className="ln-u-hard-left b-scaffold-max-width" size={{ xs: '1/1' }}>
            <Switch>
              <Route path='/' exact component={Information} />
              <Route path='/search' exact component={Search} />
              <Route path='/placeholder' exact component={Placeholder} />
              <Route path='/incidentDetails' exact component={IncidentDetails} />
              <Route component={Error} />
            </Switch>
          </GridItem>
        </GridWrapper>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Modal restrictClose open>
          <h4> Redirecting.. </h4>
        </Modal>
      </UnauthenticatedTemplate>
    </>
  )
}

App.propTypes = {
  isLoading: PropTypes.bool,
}

App.defaultProps = {
  isLoading: false
}

export default App
