import React from 'react'
import { PropTypes } from 'prop-types'

import { Card } from '@jsluna/card'
import { FilledButton as Button } from '@jsluna/button'
import { Display1, Display3, Body1 } from '@jsluna/typography'

const CardWithButton = (props) => {
    const { title, text, buttonText, onButtonClick, isError, buttonID } = props
    const TitleDisplay = isError === false ? Display1 : Display3

    return (
        <>
            <Card>
                <TitleDisplay>{title}</TitleDisplay>
                <div className="ln-u-margin-top">
                    <Body1>{text}</Body1>
                </div>
                <Button
                    id={buttonID}
                    fullWidth
                    className="ln-u-margin-top*2"
                    onClick={onButtonClick}
                >
                    {buttonText}
                </Button>
            </Card>
        </>
    )
}

CardWithButton.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func.isRequired,
    isError: PropTypes.bool.isRequired,
    buttonID: PropTypes.string,
}

CardWithButton.defaultProps = {
    isError: false,
    text: null,
    buttonID: null
}

export default CardWithButton