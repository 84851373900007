import { useEffect } from 'react'
import { useApiClient } from '../utils/azure-ad/AppProvider'
import apiBaseUrl from '../utils/apiConfig'
import { store } from '../store/index'
import { setIsLoading } from '../store/loading/actions'
import { setUserFullName } from '../store/user/actions'

function User() {
  const apiClient = useApiClient()

  useEffect(() => {
    const handleError = (errorText, error) => {
    store.dispatch(setIsLoading(false))
    console.log(errorText)
    console.log(error)
  }

  async function getUserFullName() {
    const userFullNameUrl = `${apiBaseUrl}/User/FullName`
    store.dispatch(setIsLoading(true))
    const fullName = await apiClient.get(userFullNameUrl)
      .then((response) => {
        store.dispatch(setIsLoading(false))
        return response.text()
      })
      .catch((error) => handleError('Error getting full name of user', error))
    store.dispatch(setUserFullName(fullName))
  }
  
  getUserFullName()
  }, [apiClient])

  return null
}

export default User
