import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import './main.scss'
import reportWebVitals from './reportWebVitals'
import App from './App/index'
import * as store from './store'

import { msalConfig } from './utils/azure-ad/authConfig'
import { apiConfig } from './utils/azure-ad/apiClientConfig'
import { AppProvider } from './utils/azure-ad/AppProvider'

const msalInstance = new PublicClientApplication(msalConfig)

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <AppProvider apiScope={apiConfig.apiClientScope}>
        <Provider store={store.store}>
          <PersistGate loading={null} persistor={store.persistor}>
            <Router basename={'/pfs-return-to-pay'}>
              <App />
            </Router>
          </PersistGate>
        </Provider>
      </AppProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
