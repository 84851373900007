import React from 'react'
import { useMsal } from '@azure/msal-react'

function Logout() {
  const { instance } = useMsal()

  const handleLogout = (logoutType) => {
    if (logoutType === 'popup') {
      instance.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/'
      })
    } else if (logoutType === 'redirect') {
      instance.logoutRedirect({
        postLogoutRedirectUri: '/'
      })
    }
  }

  return (
    <button
      className="c-text-button-link ln-u-margin-left*2"
      id="logout-link"
      type="button"
      onClick={() => handleLogout('redirect')}
    >
      Logout
    </button>
  )
}

export default Logout
