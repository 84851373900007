import
{
  IPublicClientApplication,
  AuthenticationResult
} from '@azure/msal-browser'

export default class ApiClient {
  constructor(instance, apiScope) {
    // eslint-disable-next-line no-unused-expressions, no-sequences
    this.instance = instance,
    this.apiScope = apiScope
  }

  async getAccessToken() {
    const accounts = this.instance.getAllAccounts()

    if (accounts.length === 0) {
      return null
    }

    const account = accounts[0]

    try {
      const authResult = await this.instance.acquireTokenSilent({
        account,
        scopes: [this.apiScope]
      })

      return authResult.accessToken
    } catch (error) {
      console.log(error)
      return null
    }
  }

  async get(url) {
    const accessToken = await this.getAccessToken()

    if (!accessToken) {
      throw new Error('Access token not available')
    }

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`
    })

    const requestOptions = {
      method: 'GET',
      headers
    }

    try {
      const response = await fetch(url, requestOptions)

      const data = await response

      return data
    } catch (error) {
      throw new Error()
    }
  }

  async post(url, formData) {
    const accessToken = await this.getAccessToken()

    if (!accessToken) {
      throw new Error('Access token not available')
    }

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    })

    const requestOptions = {
      method: 'POST',
      headers,
      body: JSON.stringify(formData)
    }

    try {
      const response = await fetch(url, requestOptions)

      const status = await response.status

      return status
    } catch (error) {
      throw new Error()
    }
  }

  async put(url) {
    const accessToken = await this.getAccessToken()

    if (!accessToken) {
      throw new Error('Access token not available')
    }

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`
    })

    const requestOptions = {
      method: 'PUT',
      headers
    }

    try {
      const response = await fetch(url, requestOptions)

      const status = await response.status

      return status
    } catch (error) {
      throw new Error()
    }
  }
}
