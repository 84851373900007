import { connect } from 'react-redux'
import IncidentDetails from './IncidentDetails'

import { setSelectedIncident, setSearchResults, setSearchedType, setSearchedString } from '../../store/searchHistory/actions'
import { setIncidentPaid } from '../../store/incident/actions'
import { setIsLoading } from '../../store/loading/actions'


const mapStateToProps = state => ({
    selectedIncident: state.searchHistory.selectedIncident,
    incidentPaid: state.incident.incidentPaid,
    searchedType: state.searchHistory.searchedType,
    searchedString: state.searchHistory.searchedString
})

const mapDispatchToProps = dispatch => ({
    setSelectedIncident: (state) => {
        dispatch(setSelectedIncident(state))
    },
    setIncidentPaid: (state) => {
        dispatch(setIncidentPaid(state))
    },
    setSearchResults: (state) => {
        dispatch(setSearchResults(state))
    },
    setSearchedType: (state) => {
        dispatch(setSearchedType(state))
    },
    setSearchedString: (state) => {
        dispatch(setSearchedString(state))
    },
    setIsLoading: (state) => {
        dispatch(setIsLoading(state))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(IncidentDetails)